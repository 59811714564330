import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "published": false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tag = makeShortcode("Tag");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "release-notes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#release-notes",
        "aria-label": "release notes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Release Notes`}</h1>
    <h2 {...{
      "id": "november",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#november",
        "aria-label": "november permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`November`}</h2>
    <ul>
      <li parentName="ul">
 <Tag title="Two New Vault Types & More" tagsList={['new']} mdxType="Tag" />
        <p parentName="li">{` The latest release includes two new vault types: Customer Identity Vault and Payment Vault, significant performance enhancements, new features, a brand-new user interface, and features for quick customer adoption.`}</p>
      </li>
      <li parentName="ul">
        <Tag title="Postman Collections" tagsList={['new']} mdxType="Tag" />
        <p parentName="li">{`Just created a new vault and can’t wait to give it a try? You can now generate a Postman Collection for your vault from the Vault Browser, as shown in the GIF below! Import this into Postman, and you’re off to the races.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/352340a7d4694120bdf6fa21cfb2ca15/postman_select.gif",
            "alt": "postman"
          }}></img></p>
      </li>
      <li parentName="ul">
        <Tag title="Connections" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{`Connections are a Skyflow-managed service that allows you to quickly and securely connect your data vault with third-party services for data processing. By using Skyflow connections, you offload compliance requirements to Skyflow.`}</p>
      </li>
      <li parentName="ul">
        <Tag title="CRUD operations support" tagsList={['new']} mdxType="Tag" />
        <p parentName="li">{`The Java, Python, and Node server-side SDKs now support CRUD operations on data in a vault, making it easier for developers to integrate with Skyflow vaults. Developed with a GOlang SDK that includes authentication and vault CRUD operations.`}</p>
      </li>
      <li parentName="ul">
 <Tag title="UI element updates" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` Added support for custom UI elements, unmounting elements in the UI to be able to reset them to their default state, formatting for expiration date and card_number fields, and added support for custom validation rules.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "september",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#september",
        "aria-label": "september permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`September`}</h2>
    <ul>
      <li parentName="ul">
 <Tag title="Administration Workflows" tagsList={['new']} mdxType="Tag" />
        <p parentName="li">{` Admins control Skyflow instances with a new admin role and a console using administrative workflows.`}</p>
      </li>
      <li parentName="ul">
 <Tag title="Service Accounts" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` Service accounts are machine to machine accounts expressly set up to integrate a service (app or machine) with Skyflow vaults without the context of a human user. Service accounts improve the security of the system and add another layer of access control.`}</p>
      </li>
      <li parentName="ul">
 <Tag title="Authentication & Authorization Enhancements" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` Enhanced authentication and authorization with interview-based login and security best practices further protect users and their data.`}</p>
      </li>
      <li parentName="ul">
 <Tag title="Enhanced Documentation Support" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` A new developer micro-site makes it easier for developers to get started with the Skyflow API.`}</p>
      </li>
      <li parentName="ul">
 <Tag title="Workspaces" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` A Workspace is a dedicated cluster infrastructure to deploy vaults. Workspaces allow logical groupings of vaults and resources geared to a specific goal make it easier for developers to test and validate their workflows. `}</p>
      </li>
      <li parentName="ul">
 <Tag title="Platform Enhancements" tagsList={['improved']} mdxType="Tag" />
        <p parentName="li">{` New platform features make it easier for users to manage notifications, vaults, and resources`}</p>
        <p parentName="li">{` `}<img parentName="p" {...{
            "src": "/80762727a029e943023b61b2cec2bb2e/data_vault_pillars.png",
            "alt": "data_vault_pillars"
          }}></img></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      